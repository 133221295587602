import React from "react";
import { REACT_APP_S3_ASSETS_URL } from "../contstant";

export default function Footer() {
    return (
        <footer id="footer">
            <div className="footer-content">
                <div className="footer">
                    <div className="footer-left">
                        <div className="footer-left-inner">
                            <div className="footer-col">
                                <strong className="title mb--20 fw--700">Explore</strong>
                                <ul className="list-none">
                                    <li>
                                        <a href={process.env.REACT_APP_S3_PRIME_URL} target="_blank" rel="noopener">Virtua Prime</a>
                                    </li>
                                    <li>
                                        <a href={process.env.REACT_APP_S3_ISLAND_URL} target="_blank" rel="noopener">Virtua Island</a>
                                    </li>
                                    <li>
                                        <a href="/">Land Bots</a>
                                    </li>
                                    {/* <li>
                                        <a href={process.env.REACT_APP_S3_LAB_URL} target="_blank" rel="noopener">Virtua Labs</a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="footer-col">
                                <strong className="title mb--20 fw--700">About</strong>
                                <ul className="list-none">
                                    <li>
                                        <a href="https://virtua.gitbook.io/virtua-wiki/" target="_blank" rel={"noreferrer"}>Wiki</a>
                                    </li>
                                    <li>
                                        <a href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/the-virtua-team" target={"_blank"} rel={"noreferrer"}>Our Team</a>
                                    </li>
                                    <li>
                                        <a href="https://blog.virtua.com/" target="_blank" rel={"noreferrer"}>Blog</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-col">
                                <strong className="title mb--20 fw--700">Links</strong>
                                <ul className="list-none">
                                    <li>
                                        <a href={process.env.REACT_APP_S3_MARKETPLACE_URL} target="_blank" rel="noopener">Marketplace</a>
                                    </li>
                                    <li>
                                        <a href={process.env.REACT_APP_S3_VANRY_URL} target="_blank" rel="noopener">VANRY</a>
                                    </li>
                                    {/* <li>
                                        <a href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/tvk/prestige" target={"_blank"} rel={"noreferrer"}>Prestige Program</a>
                                    </li>
                                    <li>
                                        <a href={process.env.REACT_APP_S3_TVK_URL} target="_blank" rel="noopener">Virtua Kolect</a>
                                    </li> */}
                                    <li>
                                        <a href={process.env.REACT_APP_S3_CONTACT_URL} target="_blank" rel="noopener">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex items-center web-footer-text">
                            <img src={`${REACT_APP_S3_ASSETS_URL}virtua-footer.svg`} alt="footer-logo" />
                            <span className="title">Virtua is a registered trademark of Virtua Limited. All logos are registered trademarks of their respective owners. All content of this document, unless otherwise credited, are copyright @ {new Date().getUTCFullYear()} Virtua Limited.</span>
                        </div>
                    </div>
                    <div className="footer-right">
                        <div className="footer-right-inner">
                            <div>
                                <h4 className="title mb--20 fw--700">Join Our Community</h4>
                                {/* <div className="input-holder">
                            <input type="email" placeholder="Email Address" className="input-style" />
                            <span style="pointer-events: auto;">
                                <img src="./images/arrow.svg" alt="arrow" />
                            </span>
                        </div> */}
                                <ul className="socials list-none">
                                    <li>
                                        <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://www.facebook.com/VirtuaMetaverse/" target="_blank" rel={"noreferrer"}>
                                            <img className="icon" src={`${REACT_APP_S3_ASSETS_URL}facebook.svg`} alt="facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://twitter.com/Virtuametaverse" target="_blank" rel={"noreferrer"}>
                                            <img className="icon" src={`${REACT_APP_S3_ASSETS_URL}twitter.svg`} alt="twitter" />
                                        </a>
                                    </li>
                                    <li><a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://www.instagram.com/Virtuametaverse/" target="_blank" rel={"noreferrer"}>
                                        <img className="icon" src={`${REACT_APP_S3_ASSETS_URL}instagram.svg`} alt="instagram" />
                                    </a>
                                    </li>

                                    <li>
                                        <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://www.youtube.com/VirtuaMetaverse" target="_blank" rel={"noreferrer"}>
                                            <img className="icon" src={`${REACT_APP_S3_ASSETS_URL}youtube.svg`} alt="youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://discord.com/invite/virtuaofficial" target="_blank" rel={"noreferrer"}>
                                            <img className="icon" src={`${REACT_APP_S3_ASSETS_URL}discord.svg`} alt="discord" />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://medium.com/terravirtua" target="_blank" rel={"noreferrer"}>
                                            <img className="icon" src={`${REACT_APP_S3_ASSETS_URL}medium.svg`} alt="medium" />
                                        </a>
                                    </li>
                                    {/* <li>
                                <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://www.reddit.com/user/terravirtua" target="_blank" rel={"noreferrer"}>
                                    <img src="assets/imgs/social/footer/reddit.svg" alt="reddit" className="icon" />
                                </a>
                            </li> */}
                                    {/* <li>
                                <a className="sc-eDvSVe eWkNa-d flex items-center flex-text-center" href="https://t.me/Virtuametaverse" target="_blank" rel={"noreferrer"}>
                                    <img src="assets/imgs/social/footer/telegram.svg" alt="telegram" className="icon" />
                                </a>
                            </li> */}
                                </ul>
                            </div>
                            <div className="footer-buttons">
                                <h4 className="title mb--20 fw--700">Download Our Apps</h4>
                                <div className="btnsHolder">
                                    <a href="https://apps.apple.com/pk/app/virtua-metaverse/id1597744060" target="_blank" className="btn custom-btn-light-outline lg" rel={"noreferrer"}>
                                        <span className="icon">
                                            <img src={`${REACT_APP_S3_ASSETS_URL}app-store.svg`} alt="app-store" />
                                        </span>App Store</a>
                                    <a href="https://play.google.com/store/apps/details?id=com.terravirtua.prod" target="_blank" className="btn  custom-btn-light-outline lg" rel={"noreferrer"}>
                                        <span className="icon">
                                            <img src={`${REACT_APP_S3_ASSETS_URL}play-store.svg`} alt="play-store" />
                                        </span>Google Play
                                    </a>
                                </div>
                            </div>
                            <div className="mobile-footer-text">
                                <img src={`${REACT_APP_S3_ASSETS_URL}virtua-footer.svg`} alt="footer-logo" />
                                <span className="title">Virtua is a registered trademark of Virtua Limited. All logos are registered trademarks of their respective owners. All content of this document, unless otherwise credited, are copyright @ {new Date().getUTCFullYear()} Virtua Limited.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-list">
                <div className="footer-content">
                    <div className="bottom-wrap">
                        <ul className="list-none flex items-center flex-text-center user-guidelines">
                            <li>
                                <a href={process.env.REACT_APP_S3_GUIDELINE_URL} target="_blank" rel="noopener">User Guidelines</a>
                            </li>
                            <li>
                                <a href={process.env.REACT_APP_S3_POLICY_URL} target="_blank" rel="noopener">Privacy Policy</a>
                            </li>
                            <li>
                                <a href={process.env.REACT_APP_S3_TERMS_URL} target="_blank" rel="noopener">Terms of Service</a>
                            </li>
                        </ul>
                        <div className="flex items-center powered-text">
                            Powered by
                            <a href={`${process.env.REACT_APP_S3_VANARCHAIN_URL}`} className="" target="_blank" rel="noreferrer">
                                <img src={`${REACT_APP_S3_ASSETS_URL}powered-by-vanar.svg`} alt="powered-by-vanar" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
