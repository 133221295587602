import React, { useEffect, useState } from "react";
// import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useRef } from "react";
import Footer from "../../components/Footer";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { REACT_APP_S3_ASSETS_URL } from "../../contstant";
// const options = {
//   margin: 50,
//   nav: true,
//   dots: false,
//   autoplay: true,
//   center: true,
//   loop: true,
//   item:5,
//   smartSpeed: 1000,
//   responsiveClass:true,
//   responsive: {
//       0: {
//           items: 1,
//       },
//       400: {
//           items: 1,
//       },
//       600: {
//           items: 2,
//       },
//       700: {
//           items: 3,
//       },
//       1000: {
//           items: 3,

//       }
//   },
// };
export default function LandingPage() {

  // const scroll = new useLocomotiveScroll({
  //   el: document.querySelector('[data-scroll-container]'),
  //   smooth: true
  // });


  const [toggleMenu, setToggleMenu] = useState(true);

  const ref = useRef(null);
  // const locoOptions = {
  //   smooth: true,
  //   smartphone: {
  //     smooth: true
  //   },
  //   tablet: {
  //     smooth: true
  //   }
  // }



  // header menu code
  const stickyHeader = () => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add("active");
    } else {
      header.classList.remove("active");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickyHeader,);
    return () => {
      window.removeEventListener("scroll", stickyHeader,);
    };
  }, []);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };



  return (
    <div >
      <header id="header" className="fixed" >
        <div className="header-container">
          <nav className="navigation">
            <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center" }}>

              <a href={process.env.REACT_APP_S3_VIRTUA_URL} target="_blank" rel="noopener" className="mainSiteLogo">
                <img src={`${REACT_APP_S3_ASSETS_URL}home_white_icon.svg`} alt="" />
              </a>
              <a href="/" className="logo-home">
                <img src={`${REACT_APP_S3_ASSETS_URL}virtua-logo.svg`} className="header-logo" alt="" />
              </a>
            </div>
            <div className={toggleMenu ? "hamburger relative" : "hamburger relative change"} onClick={toggleNav}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>

            <div className="navigation-menu">
              <ul className="social-icons list-none">
                <li className="blog-text">
                  <a href="https://blog.virtua.com/" target="blank">BLOG</a>
                </li>
                <li>
                  <a href="https://discord.com/invite/virtuaofficial" target="blank">
                    <img src={`${REACT_APP_S3_ASSETS_URL}discord.svg`} alt="discord" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Virtuametaverse" target="blank">
                    <img src={`${REACT_APP_S3_ASSETS_URL}twitter.svg`} alt="twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>


      </header>


      {toggleMenu ? (
        ""
      ) : (
        <div className={!toggleMenu ? "mobile-navigation" : ""}>
          <div className="link-navigation">
            {/* <ul>
             <li>
               <a href="https://cardano.virtua.com/" target="_blank">Cardano Island</a>
             </li>
             <li>
               <a href="https://island.virtua.com/" >Virtua Island</a>
             </li>
           </ul> */}
            <ul className="social-icons">
              <li className="blog-text">
                <a href="https://blog.virtua.com/" target="blank">BLOG</a>
              </li>
              <li>
                <a href="https://discord.com/invite/virtuaofficial" target="blank">
                  <img src={`${REACT_APP_S3_ASSETS_URL}discord.svg`} alt="discord" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Virtuametaverse" target="blank">
                  <img src={`${REACT_APP_S3_ASSETS_URL}twitter.svg`} alt="twitter" />
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="social-navigation">
           <ul className="social-icons">
               <li className="blog-text"><a href="https://blog.virtua.com/" target="blank">BLOG</a></li>
               <li><a href="https://discord.com/invite/virtuaofficial" target="blank"><img src="assets/imgs/social/discord.svg" alt="discord" /></a></li>
               <li><a href="https://twitter.com/Virtuametaverse" target="blank"><img src="assets/imgs/social/twitter.svg" alt="" /></a></li>
           </ul>
       </div> */}
        </div>
      )}
      {/* <LocomotiveScrollProvider options={locoOptions} containerRef={ref} data-scroll-speed="4"> */}
      <div data-scroll-container ref={ref}>


        <div className="banner clr">
          <div className="bot mob">
            <img src={`${REACT_APP_S3_ASSETS_URL}landbot.png`} alt="landbot" />
          </div>
          <div className="txt" >
            {/* <div className="logo">
              <img src="assets/imgs/virtua-logo.svg" alt="" />
            </div> */}
            <h1 className="" ><span className="dBlock uppercase ">Unlock Your Land Plot’s Full Potential!</span>
              Virtua Land Bots – Get <br />Yours Now!</h1>
            <p>Collect unique Land Bots to get even more value from your Land Plots, and bring your metaverse experience to life! Each Bot is a one-of-a-kind 1/1 generative NFT, and comes with its own set of attributes and skills, such as the ability to mine resources from your land, craft new exciting items, discover and explore new areas of Virtua, and more. Resources can be used to craft new NFTs that you can display in your vSpace, or trade with others!</p>
            <p>Scroll down to meet our current range of Land Bots – and watch this space as we introduce more Land Bots to the Virtua metaverse…</p>
            <div className="d-inline-flex flex-row btnsHolder mb--40" >
              <a href="https://landbots.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary btn uppercase lg">
                Mint Now
              </a>
              {/* <span className="btn-primary disabled btn uppercase lg">
              MINTING OPENS ON 6TH FEB
              </span> */}
              {/* <a href="https://virtua.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary-outline btn uppercase lg">
                Claim Now
              </a> */}
              {/* <div className="scrollDown dInlineBlock">
                <div className="mouse">
                </div>
                Scroll Down
              </div> */}

            </div>
            <p>Qualifying Cardano Summit NFT holder? <a href="https://virtua-claiming.ada-anvil.io/en/" target={"_blank"} rel="noreferrer">Claim your bot here <span>
              <img src={`${REACT_APP_S3_ASSETS_URL}link-arrow.svg`} alt="link-arrow" />
            </span></a></p>
          </div>
          <div className="bot desk">
            <img src={`${REACT_APP_S3_ASSETS_URL}landbot.png`} alt="landbot" />
          </div>
        </div>
        {/* <section className="bots-carousel">
          <div className="container">
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="assets/imgs/carousel/bots-carousel-img1.png" alt="" />
                </div>
                <div className="carousel-item">
                  <img src="assets/imgs/carousel/bots-carousel-img2.png" alt="" />
                </div>
                <div className="carousel-item">
                  <img src="assets/imgs/carousel/bots-carousel-img3.png" alt="" />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </section> */}
        <section className="intro-section"  >
          <div className="bots-carousel">
            <div className="container">
              <div id="carouselBotsFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselBotsFade" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselBotsFade" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselBotsFade" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={`${REACT_APP_S3_ASSETS_URL}bots-carousel-img1.png`} alt="bots" />
                  </div>
                  <div className="carousel-item">
                    <img src={`${REACT_APP_S3_ASSETS_URL}bots-carousel-img2.png`} alt="bots" />
                  </div>
                  <div className="carousel-item">
                    <img src={`${REACT_APP_S3_ASSETS_URL}bots-carousel-img3.png`} alt="bots" />
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselBotsFade" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselBotsFade" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
          <div className="container"  >
            <div className="row">
              <div className="col-lg-6" data-scroll data-scroll-speed="1" >
                <div className="botImgHolder top">
                  <img src={`${REACT_APP_S3_ASSETS_URL}miner-bot-img.png`} alt="miner-bot" />
                </div>
              </div>
              <div className="col-lg-6" data-scroll data-scroll-speed="2">
                <div className="txt h-100">
                  <h4 className="title accent-clr">
                    <span className="sub dBlock wht-clr uppercase">Available Now!</span>Cardano Island Miner Bot

                  </h4>
                  <div className="listing">
                    <ul>
                      <li className=" wht-clr">Unique generative 1/1 NFT asset</li>
                      <li className=" wht-clr">Twin Trait Sets: Use as a PFP and use inside the Virtua metaverse</li>
                      <li className=" wht-clr">Available in 5 rarity levels</li>
                      <li className=" wht-clr">Enables faster resource collection</li>
                      <li className=" wht-clr">Rarer Bots provide additional benefits, including increased resource storage, automated resource claiming, & more!</li>
                    </ul>
                  </div>
                  <div className="d-inline-flex flex-row btnsHolder" >
                    <a href="https://landbots.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary btn uppercase lg">
                      Mint Now
                    </a>
                    {/* <span className="btn-primary disabled btn uppercase lg">
                    MINTING OPENS ON 6TH FEB
                  </span> */}
                    {/* <a href="https://virtua.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary-outline btn uppercase lg">
                Claim Now
              </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 txtHolder" data-scroll data-scroll-speed="2">
                <div className="txt h-100">
                  <h4 className="title ">
                    What Are Land Bots?
                  </h4>
                  <div className="listing">
                    <p className="mb--0 wht-clr">Land Bots are native to Virtua, and share a deep connection to our metaverse. Not only do they enable you to harness resources from your Land Plots – these amazing cybernetic creatures also allow you to craft new items, upgrade metaverse items, unlock new features, explore new areas, and so much more.</p>
                  </div>
                  <div className="d-inline-flex flex-row btnsHolder" >
                    <a href="https://landbots.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary btn uppercase lg">
                      Mint Now
                    </a>
                    {/* <span className="btn-primary disabled btn uppercase lg">
                    MINTING OPENS ON 6TH FEB
                  </span> */}
                    {/* <a href="https://virtua.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary-outline btn uppercase lg">
                Claim Now
              </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 botHolder" data-scroll data-scroll-speed="1">
                <div className="botImgHolder top">
                  <img src={`${REACT_APP_S3_ASSETS_URL}land-bot-img.png`} alt="land-bot" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">

              <div className="col-lg-6 botHolder" data-scroll data-scroll-speed="1">
                <div className="botImgHolder top">
                  <img src={`${REACT_APP_S3_ASSETS_URL}land-bot-special-img.png`} alt="land-bot-special" />
                </div>
              </div>
              <div className="col-lg-6 txtHolder" data-scroll data-scroll-speed="2">
                <div className="txt h-100">
                  <h4 className="title ">
                    What Makes Them Special?
                  </h4>
                  <div className="listing">
                    <p className="wht-clr">
                      Land Bots are a unique form of generative NFT. Each Bot is visually unique, and is a true 1 of 1. However, Virtua Land Bots are not just another NFT collection. They bring together TWO sets of unique traits and attributes, to create the ultimate in Web3 utility.
                    </p>
                    <p className="wht-clr">
                      Each Bot’s <em>external</em> traits showcase a range of stunning visual rarities, making them amazing to look at. However, every Land Bot also carries a set of <em>internal</em> meta attributes buried deep inside it, with 1 primary skill meta attribute and up to 5 other awesome meta skill attributes which add incredible value when you use them in the Virtua metaverse.
                    </p>
                    <p className="mb--0 wht-clr">This means you can collect your own Land Bots as unique works of art, as workers in the metaverse, or both. The power lies in your hands!</p>
                  </div>
                  <div className="d-inline-flex flex-row btnsHolder" >
                    <a href="https://landbots.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary btn uppercase lg">
                      Mint Now
                    </a>
                    {/* <span className="btn-primary disabled btn uppercase lg">
                  MINTING OPENS ON 6TH FEB
                  </span> */}
                    {/* <a href="https://virtua.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary-outline btn uppercase lg">
                Claim Now
              </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 txtHolder" data-scroll data-scroll-speed="2">
                <div className="txt h-100">
                  <h4 className="title ">
                    No Land? No Problem!
                  </h4>
                  <div className="listing">
                    <p className="mb--0 wht-clr">Even if you don’t own Land in Virtua, you can still buy, collect, and have fun with your very own Land Bots! In addition to each Bot’s PFP functionality, you’ll also be able to use your Land Bots in special “Prime Quests” across all of Virtua Prime, mini-games, and mining events that will take place from time to time – and see your position on event leaderboards.</p>
                  </div>
                  <div className="d-inline-flex flex-row btnsHolder" >
                    <a href="https://landbots.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary btn uppercase lg">
                      Mint Now
                    </a>
                    {/* <span className="btn-primary disabled btn uppercase lg">
                  MINTING OPENS ON 6TH FEB
                  </span> */}
                    {/* <a href="https://virtua.ada-anvil.io/en/" target={"_blank"} rel="noreferrer" className="btn-primary-outline btn uppercase lg">
                Claim Now
              </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 botHolder" data-scroll data-scroll-speed="1">
                <div className="botImgHolder top quest-img">
                  <img src={`${REACT_APP_S3_ASSETS_URL}quest_img.png`} alt="quest" />
                </div>
              </div>
            </div>
          </div>
          <div className="bottomSection">
            <div className="faqs-section">
              <div className="container mb--0">
                <div className="txt">
                  <h4 className="title text-center ">
                    Frequently Asked Questions
                  </h4>
                </div>

                <div className="customTabs">
                  <div className="text-center">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Land Bots</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Resources</button>
                      </li>

                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              What are Land Bots?
                            </button>
                          </h2>
                          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--15">Land Bots help you get even more value from your Virtua Land Plots, and bring your metaverse experience to life!</p>
                              <p className="mb--15">Each Bot is a one-of-a-kind 1/1 generative NFT, and comes with its own set of attributes and skills – such as the ability to mine resources from your land, craft new exciting items, discover and explore new areas of Virtua, and more. </p>
                              <p className="mb--15">Resources can be used to craft new NFTs that you can display in your vSpace, or trade with others!</p>
                              <p className="mb--0">You can also use your Land Bots as incredibly cool PFP assets.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              How will the Land Bots minting process work?
                            </button>
                          </h2>
                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--0">The Land Bots minting process will use a “blind box” mechanic, so that each Land Bot will be randomly assigned when it is minted. This is to ensure that the minting and assignment process is as fair as possible.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              How much will the Land Bots cost?
                            </button>
                          </h2>
                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--0">The first Miner Land Bots will be 120 ADA.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Will holders of all 7 Cardano Summit NFTs get a free Land Bot?
                            </button>
                          </h2>
                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--0">Yes.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              What if I have a Land Bot but no Land Plot?
                            </button>
                          </h2>
                          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {/* <p className="mb--15">If you don't own a Land Plot, you can still craft from resources – but you will have to buy those resources from marketplaces.</p>
                        <p className="mb--0">However, if you do own a Land Bot, you can use it in crafting, enabling you to create rarer NFTs. Different Land Bots can craft different NFTs. For example, if you have Gold, without a Land Bot you could craft a gold ingot which could be more valuable. However, using a Scientist Bot may enable you to craft Gold into a rare gold-plated Williams F1 helmet!</p> */}
                              <p className="mb--15">If you don’t own a Land Plot, you can still use your bot! You can also take part in Prime Quests - adventures which will appear across Virtua, allowing you to utilise your bot in new and exciting ways. You can also craft from resources, after acquiring what you need from the resource marketplace.</p>
                              <p className="mb--0">Owning a Land Bot means you can use it in crafting, enabling you to create rarer NFTs. Different Land Bots can craft different NFTs. For example, if you have Gold, without a Land Bot you could craft a gold ingot which could be more valuable. However, using a Scientist Bot may enable you to craft Gold into a rare gold-plated Shelby Cobra!</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      What if I own a Land Plot, but do not own a Land Bot?
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="mb--0">Land emits resources irrespective of anything else you own. You can craft these resources or sell them, with no need for a Land Bot. Land Bots increase emission rates, and enable the creation of rarer NFTs or other special items.</p>
                      </div>
                    </div>
                  </div> */}
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                              What are the Land Bots’ visual traits?
                            </button>
                          </h2>
                          <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--15">Land Bots have the following visual traits:</p>
                              <ul className="">
                                <li className="mb--15">
                                  <h6>BODY TYPE</h6>
                                  <p className="mb--0">Bots have different body types. </p>
                                </li>
                                <li className="mb--15">
                                  <h6>HEAD TYPE</h6>
                                  <p className="mb--0">Bots have different head types based on how their specialisations have evolved. Some have bright lights, others have laser lights or LEDs.</p>
                                </li>
                                <li className="mb--15">
                                  <h6>BACKPACK</h6>
                                  <p className="mb--0">Backpacks allow Bots to slot in some modifications that enhance their capabilities, ranging from extra arms to jetpack thrusters.</p>
                                </li>
                                <li className="mb--15">
                                  <h6>BUDDIES</h6>
                                  <p className="mb--0">Some Land Bots come with anti-grav robots, which act like their virtual communication assistants.</p>
                                </li>
                                <li className="mb--15">
                                  <h6>CHEST DEVICE</h6>
                                  <p className="mb--0">Some Bots choose to have accessories attached to their chest region, which enhance certain aspects of their working kit.</p>
                                </li>
                                <li className="mb--15">
                                  <h6>HAND ACCESSORY</h6>
                                  <p className="mb--0">Some bots have attachments on or near their hand, which help them to complete their tasks or provide some safety.</p>
                                </li>
                                <li className="mb--15">
                                  <h6>COLOUR</h6>
                                  <p className="mb--0">Not every aspect of the Land Bots serves a functional purpose – sometimes the Bots just want to show off their style, and they do this by changing up their colours so they don’t all look the same!</p>
                                </li>
                                <li className="mb--15">
                                  <h6>BACKGROUND</h6>
                                  <p className="mb--0">Every Bot’s picture has its own visual background, ranging from plain white to an elusive golden one!</p>
                                </li>
                                <li className="mb--15">
                                  <h6>LEG ACCESSORY</h6>
                                  <p className="mb--0">Some Bots choose to add extra plating to their knees – they don’t want their paint to get scuffed!</p>
                                </li>
                                <li className="mb--15">
                                  <h6>IDENTIFIER</h6>
                                  <p className="mb--0">Certain Bots choose to carry specific insignias to show off their pride of their land! Some carry a single badge, whereas others carry the elusive full set!</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                              Are Land Bots the same as VFLECTs?
                            </button>
                          </h2>
                          <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--0">VFLECTs and Land Bots are very different, and provide different functions and utility within the Virtua metaverse.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                              Are the Land Bots a PFP project, or are they metaverse assets?
                            </button>
                          </h2>
                          <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p className="mb--0">They’re both at the same time. If you own a Land Bot, you can decide how to use it.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOneResources">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneResources" aria-expanded="true" aria-controls="collapseOneResources">
                              What are resources, and what can I do with them?
                            </button>
                          </h2>
                          <div id="collapseOneResources" className="accordion-collapse collapse show" aria-labelledby="headingOneResources" data-bs-parent="#accordionExample1">
                            <div className="accordion-body">
                              <p className="mb--15">All land on Cardano Island emits resources. You do not mine resources, but you can increase their rate of emission with Land Bots.</p>
                              <p className="mb--15">There are 6 resources on Cardano Island:</p>
                              <ul className="mb--15">
                                <li>Iron</li>
                                <li>Gold</li>
                                <li>Sticky Goo</li>
                                <li>Wood</li>
                                <li>Electro Rock</li>
                                <li>Cardonium (only available on legendary land plots, and exclusive to Cardano Island)</li>
                              </ul>
                              <p className="mb--0">Resources can be sold on a marketplace, or crafted into NFTs. Land Bots enable crafting of even <em>rarer</em> NFTs. All of these resources are emitted at different rarity levels, and some resources are rarer than others. Cardonium is the rarest of resources, and you can craft ultra-rare collectibles with it, or sell it in the marketplace. Sometimes, combining Land Bots and using certain combinations may create ultra-rare resources and collectibles.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwoResources">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoResources" aria-expanded="false" aria-controls="collapseTwoResources">
                              How are resources different from one another?
                            </button>
                          </h2>
                          <div id="collapseTwoResources" className="accordion-collapse collapse" aria-labelledby="headingTwoResources" data-bs-parent="#accordionExample1">
                            <div className="accordion-body">
                              <p className="mb--15">Land Plots emit valuable and usable resources. Some resources are scarce, while others are common, and the resources that a Land Plot emits will depend on the rarity of that Land Plot.</p>
                              <p className="mb--0">For example, Sticky Goo is a cheap resource, and will be emitted by 4,000 common and 3,000 uncommon plots. Cardonium is the rarest resource, and only 299 legendary plots will have the ability to emit it!</p>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>

            <div className="landbots-carousel clr">
              <div className=" text-center mb--80 pl--15 pr--15">
                <h4 className="title accent-clr">
                  <span className="sub dBlock uppercase wht-clr">More Virtua Land Bots</span>Coming Soon
                </h4>
              </div>

              <div className="bots-img">
                <img src={`${REACT_APP_S3_ASSETS_URL}bots_web_banner.png`} alt="bots-banner" />
              </div>

              {/* <OwlCarousel className="owl-theme"  {...options}>
                <div className="imgHolder">
                  <img src="assets/imgs/bots/1.png" alt="" />
                </div>
                <div className="imgHolder">
                  <img src="assets/imgs/bots/2.png" alt="" />
                </div>
                <div className="imgHolder">
                  <img src="assets/imgs/bots/3.png" alt="" />
                </div>
                
            </OwlCarousel> */}
            </div>
            {/* <div className="circle" data-scroll data-scroll-speed=".025"></div> */}
          </div>
        </section>
        <Footer />
      </div>

      {/* </LocomotiveScrollProvider> */}


    </div>
  );
}
