import React, { useEffect } from "react";
// import Footer from "./components/Footer";
import LandingPage from "./pages/LandingPage";
import { REACT_APP_META_INFO_ROBOTS } from "./contstant";

function App() {
  useEffect(() => {
    let metaInfoContent = document.createElement('meta')
    metaInfoContent.setAttribute('name', 'robots')
    if (REACT_APP_META_INFO_ROBOTS === 'true') {
      metaInfoContent.setAttribute('content', 'index, follow')
    } else {
      metaInfoContent.setAttribute('content', 'noindex, nofollow')
    }
    document.head.appendChild(metaInfoContent);
    return () => {
      document.head.removeChild(metaInfoContent);
    };
  }, [])
  return (
    <>
      <LandingPage />
      {/* <Footer /> */}
    </>
  );
}

export default App;
